export default {
  'About us': 'Chi siamo',
  'Add new address': 'Aggiungi nuovo indirizzo',
  'Add to compare': 'Aggiungi per confrontare',
  'Add to Wishlist': 'Aggiungi alla lista dei desideri',
  'Additional Information': 'Informazioni aggiuntive',
  'Allow order notifications': 'Consenti notifiche ordini',
  Apply: 'Applica',
  'Attention!': 'Attenzione!',
  'Back to home': 'Torna alla home page',
  'Back to homepage': 'Torna alla homepage',
  'Billing address': 'Indirizzo di fatturazione',
  Brand: 'Marca',
  Cancel: 'Annulla',
  Categories: 'Categorie',
  'Change password your account': 'Se desideri modificare la password per accedere al tuo account, inserisci le seguenti informazioni',
  Change: 'Cambia',
  'Clear all': 'Cancella tutto',
  Color: 'Colore',
  'Commercial information': 'accetto di ricevere informazioni commerciali personalizzate dal marchio via e-mail',
  'Contact details updated': 'Mantieni aggiornati i tuoi indirizzi e dettagli di contatto.',
  'Contact us': 'Contattaci',
  'Continue to billing': 'Continua con la fatturazione',
  'Continue to payment': 'Continua al pagamento',
  'Continue to shipping': 'Continua con la spedizione',
  'Cookies Policy': 'Politica sui cookie',
  'Create an account': 'Crea un account',
  'Customer Reviews': 'Recensioni dei clienti',
  Delete: 'Elimina',
  Departments: 'Dipartimenti',
  Description: 'Descrizione',
  'Details and status orders':
    'Controlla i dettagli e lo stato dei tuoi ordini nel negozio online. Puoi anche annullare il tuo ordine o richiedere un reso.',
  Discount: 'Sconto',
  Done: 'Fatto',
  'Download all': 'Scarica tutto',
  Download: 'Scarica',
  Edit: 'Modifica',
  'Email address': 'Indirizzo e-mail',
  Empty: 'Sembra che tu non abbia ancora aggiunto alcun oggetto alla borsa. Inizia a fare acquisti per riempirlo.',
  'Enjoy your free account': 'Goditi questi vantaggi con il tuo account gratuito!',
  Feedback: 'Il tuo feedback è importante per noi. Facci sapere cosa potremmo migliorare',
  'Feel free to edit': 'In questa pagina puoi aggiornare i tuoi dati personali in qualsiasi momento.',
  Filters: 'Filtri',
  'Find out more': 'Scopri di più',
  'Forgot Password Modal Email': 'Email che stai usando per accedere:',
  'Forgot Password': 'Se non ricordi la password, puoi reimpostarla.',
  'Forgotten password?': 'Password dimenticata?',
  'Go back shopping': 'Torna a fare shopping',
  'Go back to shop': 'Torna al negozio',
  'Go back': 'Torna indietro',
  Guarantee: 'Garanzia',
  'Help & FAQs': 'Aiuto e FAQ',
  Help: 'Aiuto',
  hide: 'nascondere',
  Home: 'Casa',
  'I agree to': 'Accetto',
  'I confirm subscription': "Confermo l'iscrizione",
  'Info after order':
    "Puoi accedere al tuo account utilizzando e-mail e password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, controllare la cronologia delle transazioni, modificare l'iscrizione alla newsletter.",
  Instruction1: 'Prenditi cura di me',
  Instruction2: 'Solo qui per le istruzioni di cura?',
  Instruction3: 'Sì, lo pensavamo',
  'It was not possible to request a new password, please check the entered email address.':
    "Non è stato possibile richiedere una nuova password, si prega di controllare l'indirizzo e-mail inserito.",
  Item: 'Articolo',
  Items: 'Articoli',
  Kidswear: 'Abbigliamento per bambini',
  'Let’s start now – we’ll help you': 'Iniziamo ora, ti aiuteremo.',
  'Log into your account': 'Accedi al tuo account',
  'login in to your account': 'accedi al tuo account',
  Login: 'Accedi',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Sembra che tu non abbia ancora aggiunto alcun oggetto alla borsa. Inizia a fare acquisti per riempirlo.',
  'Make an order': 'Fai un ordine',
  'Manage addresses': 'Salva i tuoi indirizzi più usati (abitazione, posto di lavoro…) in modo da poterli selezionare comodamente ad ogni ordine.',
  'Manage billing addresses':
    "Gestisci tutti gli indirizzi di fatturazione che desideri (posto di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di fatturazione con ogni ordine.",
  'Manage shipping addresses':
    "Gestisci tutti gli indirizzi di spedizione che desideri (posto di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di spedizione con ogni ordine.",
  'Match it with': 'Abbinalo a',
  'Men fashion': 'Moda uomo',
  'My Cart': 'Il mio carrello',
  'No account': 'Non hai ancora un account?',
  'On Hold': 'Bloccato/Annullato',
  'or fill the details below': 'o compila i dettagli qui sotto',
  or: 'oppure',
  'Order No.': "Numero d'ordine",
  'Order summary': "Riepilogo dell'ordine",
  'Other products you might like': 'Altri prodotti che potrebbero piacerti',
  'Password Changed': 'Password modificata correttamente. Ora puoi tornare alla home page e accedere.',
  'Pay for order': 'Paga per ordine',
  'Payment & Delivery': 'Pagamento e consegna',
  'Payment method': 'Metodo di pagamento',
  'Payment methods': 'Metodi di pagamento',
  Payment: 'Pagamento',
  'Personal details': 'Dati personali',
  'Please type your current password to change your email address.': 'Digita la password corrente per modificare il tuo indirizzo email.',
  Price: 'Prezzo',
  'Privacy Policy': 'Informativa sulla privacy',
  Privacy: 'Privacy',
  'Product suggestions': 'Suggerimenti sui prodotti',
  'The product you are looking for is not available, consider these alternatives':
    'Il prodotto che stai cercando non è disponibile, valuta queste alternative',
  Product: 'Prodotto',
  'Products found': 'Prodotti trovati',
  Products: 'Prodotti',
  'Purchase terms': 'Condizioni di acquisto',
  'Quality in the details': 'Qualità nei dettagli',
  Quantity: 'Quantità',
  'Read all reviews': 'Leggi tutte le recensioni',
  'Read and understand': "Ho letto e capito l'",
  'Read reviews': 'Leggi le recensioni',
  'Register today': 'Registrati oggi',
  'Register here': 'Registrati qui',
  Register: 'Registrati',
  'Remove from Wishlist': 'Rimuovi dalla lista dei desideri',
  'Reset Password': 'Reimposta password',
  'Review my order': 'Rivedi il mio ordine',
  'Same as shipping address': "Uguale all'indirizzo di spedizione",
  'Save changes': 'Salva modifiche',
  'Save for later': 'Salva per dopo',
  'Save Password': 'Salva password',
  Research: 'Ricerca',
  'Search results': 'Risultati della ricerca',
  'Sections that interest you': 'Sezioni che ti interessano',
  'See all results': 'Vedi tutti i risultati',
  'Select payment method': 'Seleziona metodo di pagamento',
  'Select shipping method': 'Seleziona il metodo di spedizione',
  'Send my feedback': 'Invia il mio feedback',
  'Set up newsletter': 'Spunta la casella qui sotto per essere sempre aggiornato sulle nostre novità e promozioni.',
  'Share your look': 'Condividi il tuo look',
  'Shipping address': 'Indirizzo di spedizione',
  'Shipping details': 'Dettagli di spedizione',
  'Shipping method': 'Metodo di spedizione',
  'show more': 'mostra di più',
  'Show on page': 'Mostra sulla pagina',
  'Sign in': 'Accedi',
  'Sort by': 'Ordina per',
  'Sort: Default': 'Predefinito',
  'Sort: Name A-Z': 'Nome A-Z',
  'Sort: Name Z-A': 'Nome Z-A',
  'Sort: Price from high to low': 'Prezzo da alto a basso',
  'Sort: Price from low to high': 'Prezzo da basso ad alto',
  'Sort: New arrivals': 'Nuovi Arrivi',
  'Start shopping': 'Inizia a fare shopping',
  'Subscribe to newsletter': 'Iscriviti alla newsletter',
  Subscribe: 'Iscriviti',
  Subtotal: 'Imponibile',
  'Successful placed order':
    "Hai effettuato con successo l'ordine. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato della consegna. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per monitorarne l'avanzamento.",
  'Terms and conditions': 'Termini e condizioni',
  'Thank You Inbox': 'Se il messaggio non arriva nella tua casella di posta, prova un altro indirizzo email che potresti aver usato per registrarti.',
  'Total items': 'Totale articoli',
  'Total price': 'Prezzo totale',
  Total: 'Totale',
  'Unit price': 'Prezzo unitario: ',
  'Update password': 'Aggiorna password',
  'Update personal data': 'Aggiorna i miei dati',
  'Use your personal data':
    "Attribuiamo grande importanza alle questioni relative alla privacy e ci impegniamo a proteggere i dati personali dei nostri utenti. Scopri di più su come ci prendiamo cura e utilizziamo i tuoi dati personali nell' ",
  'User Account': 'Account utente',
  'View details': 'Visualizza dettagli',
  View: 'Vista',
  'Who we are': 'Chi siamo',
  'Women fashion': 'Moda donna',
  'You can unsubscribe at any time': "Puoi annullare l'iscrizione in qualsiasi momento",
  'You currently have no orders': 'Al momento non hai ordini',
  'You haven’t searched for items yet': 'Non hai ancora cercato oggetti.',
  'We are looking for an alternative product': 'Stiamo cercando un prodotto alternativo',
  'Your bag is empty': 'La tua borsa è vuota',
  'Your current email address is': 'Il tuo indirizzo email attuale è',
  forgotPasswordConfirmation:
    "Grazie! Se c'è un account registrato con l'e-mail {0}, troverai un messaggio con un link per la reimpostazione della password nella tua casella di posta.",
  subscribeToNewsletterModalContent:
    'Dopo esserti iscritto alla newsletter, riceverai offerte speciali e messaggi da VSF via e-mail. Non venderemo o distribuiremo la tua e-mail a terzi in nessun momento. Si prega di consultare il nostro {0}.',
  'Do you want to contact us?': 'Vuoi contattarci?',
  'Fill out the form below!': 'Compila il form qui sotto!',
  'Come visit': 'Vieni a trovarci',
  'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.':
    'Nei nostri negozi, così come online, accogliamo donne di generazioni diverse. Dalle mamme alle loro giovani figlie.',
  'Follow us and stay updated': 'Seguici e rimani aggiornato',
  'FOLLOW US ON INSTAGRAM': 'SEGUICI SU INSTAGRAM',
  'Required field': 'Campo obbligatorio',
  Name: 'Nome',
  'Last name': 'Cognome',
  'Phone Number': 'N. Telofono',
  Message: 'Messaggio',
  'Send Message': 'Invia messaggio',
  'La nostra storia': 'La nostra storia',
  'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.':
    'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.',
  'Fatto con amore': 'Fatto con amore',
  'in Italia.': 'in Italia.',
  'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.':
    'Anni chiave della nostra azienda che si è indirizzata da una produzione per conto terzi alla produzione e alla vendita diretta di un nostro marchio.',
  'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.':
    'Il laboratorio si sposta in una casa coloniale al centro del paese. Attuale sede della nostra azienda a San Bonifacio.',
  'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.':
    'Laboratorio nato in provincia di Vicenza, iniziando a lavorare per grandi marchi della moda, producendo impermeabili e coprispalle di qualità.',
  'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.':
    'Il nostro prodotto è frutto di tanto amore per il nostro mestiere e crediamo nel vero Made in Italy, inteso come confezione di capi di abbigliamento realizzati con materie prime pregiate: la scelta dei tessuti che utilizziamo è fatta tenendo conto della provenienza degli stessi, con una predilezione per i materiali la cui stampa e finissaggio sono realizzati in Italia nel rispetto delle normative di sostenibilità.',
  'Find out shipping costs': 'Scopri costi di spedizione',
  'Worldwide delivery in 2/7 working days.': 'Consegna in tutto il mondo in 2/7 giorni lavorativi.',
  'Thank you for your order!': 'Grazie per il tuo ordine!',
  'Your Purchase': 'Il tuo acquisto',
  'Your Account': 'Il tuo account',
  'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.':
    "Hai effettuato correttamente l'ordine. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato di consegna. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per seguirne lo stato di avanzamento.",
  'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.':
    "Puoi accedere al tuo account utilizzando l'e-mail e la password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, delle transazioni, modificare l'iscrizione alla newsletter.",
  Contacts: 'Contatti',
  'Back to home page': 'Torna alla home page',
  'Description product': 'Descrizione prodotto',
  'Let yourself be guided by our advice': 'Lasciati guidare dai nostri consigli',
  'Here is a list of related garments': 'Ecco un elenco di capi correlati',
  Carrello: 'Carrello',
  Si: 'Sì',
  Annulla: 'Annulla',
  'Sei sicuro di voler rimuovere questo articolo dal carrello?': 'Sei sicuro di voler rimuovere questo articolo dal carrello?',
  'Acquista ': 'Acquista ',
  'Torna allo shopping': 'Torna allo shopping',
  "It looks like you haven't added any items to the bag yet. Start shopping to fill it out.":
    'Sembra che tu non abbia ancora aggiunto alcun articolo al carrello.',
  Sizes: 'Taglia',
  'Size specifications': 'Specifiche delle dimensioni',
  'Do you want to be notified if the sizes will be available again?': 'Vuoi essere avvisato se le taglie saranno nuovamente disponibili?',
  'Add to cart': 'Aggiungi al carrello',
  Story: 'Storia',
  'you discover': 'scopri',
  'Made with love in Italy': 'Fatto con amore in Italia',
  'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.':
    'I nostri capi sono realizzati con materie prime pregiate e attentamente scelte facendo attenzione alla loro provenenienza nel rispetto delle regole sulla sostenibilità.',
  'Discover ours': 'Scopri la nostra',
  'Discover our wool': 'Scopri la nostra lana',
  'Discover the new winter sweaters': 'Scopri i nuovi maglioni invernali',
  'The novelty of the moment!': 'La novità del momento!',
  'Our recommendations': 'I nostri consigli',
  Size: 'Taglia italiana',
  'Sort by relevance': 'Ordina per rilevanza',
  'Apply filters': 'Applica filtri',
  'Something went wrong during form submission. Please try again later':
    "Qualcosa è andato storto durante l'invio del modulo. Per favore riprova più tardi",
  Billing: 'Fatturazione',
  'First name': 'Nome',
  'Street name': 'Nome della strada',
  'House/Apartment number': 'Numero casa/appartamento',
  City: 'Città',
  'Zip-code': 'Cap',
  'Phone number': 'Numero di telefono',
  'Please select a country first': 'Seleziona prima un paese',
  'This field is required': 'Questo campo è obbligatorio',
  'Create an account on the store': 'Crea un account',
  'Login on the store': 'Ho già un account',
  Safety: 'Sicurezza',
  'It carefully packaged with a personal touch': 'Imballato con cura con un tocco personale',
  'Easy shipping': 'Spedizione facile',
  'You’ll receive dispatch confirmation and an arrival date': 'Riceverai la conferma della spedizione e una data di arrivo',
  'Changed your mind?': 'Hai cambiato idea?',
  'Rest assured, we offer free returns within 30 days': 'Stai tranquillo, offriamo resi gratuiti entro 30 giorni',
  'Copy address data from shipping': "Copia i dati dell'indirizzo dalla spedizione",
  Search: 'Ricerca',
  'State/Province': 'Stato/Provincia',
  Country: 'Nazione',
  'The field should have at least 2 characters': 'Il campo deve contenere almeno 2 caratteri',
  Monday: 'Lunedì',
  'Tuesday - Saturday': 'Da martedì a Sabato',
  'Tuesday - Friday': 'Da martedì a Venerdì',
  Saturday: 'Sabato',
  Sunday: 'Domenica',
  'Go to cart': 'Vai al carrello',
  'Enter promo code': 'Inserisci il codice promozionale',
  'Promo Code': 'Codice Promozionale',
  'Order review': "Revisione dell'ordine",
  'Select your Country': 'Seleziona il Paese',
  'Remember me': 'Ricordami',
  'Sign Up for Newsletter': 'Iscriviti alla newsletter',
  'I want to create an account': 'Voglio creare un account',
  'Your email': 'La tua email',
  'Your e-mail': 'Email',
  'First Name': 'Nome',
  'Personal data': 'Dati personali',
  'Password change': 'Cambio password',
  'My Account': 'Il mio account',
  'Personal Details': 'Informazioni account',
  'My profile': 'Il mio profilo',
  'Addresses details': 'I miei indirizzi',
  'My newsletter': 'Newsletter',
  'Order details': 'Dettagli degli ordini',
  'Log out': 'DISCONNETTERSI',
  'Current Password': 'Password Attuale',
  'New Password': 'Nuova Password',
  and: 'e',
  'and the': 'e la',
  'All Orders': 'Tutti gli ordini',
  'Order ID': 'ID ordine',
  Date: 'Data',
  Status: 'Stato',
  Amount: 'Totale',
  'Payment date': 'Data di pagamento',
  'Payment Date': 'Data di pagamento',
  'Order Date': 'Data di ordine',
  'My orders': 'I miei ordini',
  'Order list': 'Lista Ordini',
  'Insert your email address': 'Inserisci il tuo indirizzo email',
  'Subscribe to the newsletter': 'Iscriviti alla newsletter',
  'Please enter a valid email address.': 'Si prega di inserire un indirizzo email valido.',
  'Contacts Us': 'Contattaci',
  Services: 'Servizi',
  Shops: 'Negozi',
  Social: 'Social',
  'Add to favorites': 'Aggiungi ai preferiti',
  Share: 'Condividi',
  Remove: 'Rimuovere',
  Cart: 'Carrello',
  'Order history': 'Cronologia ordini',
  // "Manage addresses": "Gestisci gli indirizzi",
  'Add the address': "Aggiungi l'indirizzo",
  'Update the address': "Aggiorna l'indirizzo",
  'Set as default shipping': 'Imposta come spedizione predefinita',
  'Set as default billing': 'Imposta come fatturazione predefinita',
  'The field should have at least {length} characters': 'Il campo deve contenere almeno {length} caratteri',
  "Passwords don't match": 'Le password non corrispondono',
  'Passwords do not match': 'Le password non corrispondono',
  'The user account data was successfully updated!': "I dati dell'account utente sono stati aggiornati correttamente!",
  'This feature is not implemented yet! Please take a look at': "Questa funzione non è ancora implementata! Si prega di dare un'occhiata",
  'for our Roadmap!': 'per la nostra tabella di marcia!',
  Returns: 'Ritorna',
  'Repeat Password': 'Ripeti la password',
  'Last Name': 'Cognome',
  'Invalid email': 'E-mail non valida',
  'Use this address as my default one.': 'Usa questo indirizzo come quello predefinito.',
  'Default Shipping Address': 'Indirizzo di spedizione predefinito',
  'Default Billing Address': 'Indirizzo di fatturazione',
  'Shipping and returns': 'Spedizione e reso',
  'Select a shipping address': 'Seleziona un indirizzo di spedizione',
  'Use this address': 'Utilizza questo indirizzo',
  'Customer care: +39 045 7614582': 'Servizio clienti: +39 045 7614582',
  'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.':
    'Di solito arriva in 5-13 giorni lavorativi. Una sequenza temporale di spedizione specifica per la tua destinazione può essere visualizzata in Checkout.',
  'Your cart is empty': 'Il tuo carrello è vuoto',
  'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.':
    'Sembra che tu non abbia ancora aggiunto alcun articolo al carrello. Inizia a fare acquisti per compilarlo.',
  Password: 'Password',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase and lowercase letter, 1 number, and one special character (!#$%&*?@^_-)':
    'La password deve essere lunga almeno 8 caratteri e deve contenere almeno: 1 lettera maiuscola e minuscola, 1 numero e un carattere speciale (!#$%&*?@^_-)',
  'Our story': 'La nostra storia',
  'Your shopping cart is empty': 'Il tuo carrello è vuoto',
  'Search Results': 'Risultati di ricerca',
  'Discover More': 'Scopri di più',
  'Subscribe to our newsletter': 'Iscriviti alla nostra newsletter',
  'Order By': 'Ordina per',
  'Visit us': 'Vieni a trovarci',
  'Need Help?': 'Serve Aiuto?',
  'Fill out the form below and we will reach back to you!': 'Compila il form qui sotto, ti contatteremo al più presto!',
  'OUR STORY': 'LA NOSTRA STORIA',
  'We are a small family-run business with a strong bond with our country.':
    'Siamo una piccola azienda di famiglia con forti legami con il tessuto produttivo del nostro paese.',
  'The page you were looking for cannot be found': 'La pagina che stai cercando non esiste',
  'If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.':
    "Se hai digitato direttamente l'URL, assicurati che l'ortografia sia corretta. Se hai cliccato su un link per arrivare qui, il link è obsoleto.",
  'You can also click the following links to get you back on track!': 'Puoi anche fare clic sui seguenti collegamenti per rimetterti in carreggiata!',
  'Your cart': 'Il tuo carrello',
  'Go to checkout': 'Vai al checkout',
  'Bank transfer': 'Bonifico',
  'Cash on delivery': 'Contrassegno',
  Check: 'Assegno',
  'No products were found': 'Non sono stati trovati prodotti',
  'Wrong user credentials': 'Credenziali utente errate',
  'Email was not found, not available or token is expired. Please request a new password.':
    'Email non trovata, non disponibile o token scaduto. Per favore richiedi una nuova password.',
  'successfully removed from your cart': 'è stato rimosso con successo dal carrello.',
  'Ship to an address': 'Spedisci ad un indirizzo',
  'Pickup from UPS Access Point™ location': 'Ricevi presso un UPS Access Point™',
  Notes: 'Note',
  'Unable to select payment method, please try again later': 'Non è possibile selezionare il metodo di pagamento, si prega di riprovare più tardi',
  Continue: 'Continua',
  'Use another card': "Usa un'altra carta",
  'Use another account': 'Usa un altro account',
  'Unable to process payment information, please try again':
    'Non è stato possibile verificare le informazioni per il pagamento, si prega di riprovare',
  'Card type not supported. Please try with another card enabled for 3D Secure':
    'Carta non supportata. Si prega di riprovare con una carta abilitata per 3D Secure',
  'The size and color must be selected before adding a product to the cart!':
    'La taglia e il colore devono essere selezionati prima di aggiungere un prodotto al carrello!',
  'Out of stock': 'Non disponibile',
  'Italian size': 'Taglia italiana',
  'Size guide': 'Guida alle taglie',
  'Notify me if back in stock': 'Vuoi essere avvisato se questo articolo tornerà disponibile?',
  Shipping: 'Spedizione',
  'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.':
    'Le spedizioni sono effettuate con corriere UPS e vengono normalmente evase entro 48 ore. Solo in casi eccezionali (per esempio durante il periodo dei saldi) possono essere necessari 3-4 giorni lavorativi per preparare la spedizione.',
  'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.':
    'Per le consegne in Italia la spedizione è gratuita per importi pari o superiori a 100€, mentre per importi inferiori è previsto un contributo di 5€.La consegna avviene mediamente 24-48 ore dopo la spedizione dell’ordine.',
  'Shipping status can be verified at any time by using the tracking number provided via mail.':
    'Con la mail di conferma dell’ordine verrà fornito un codice di tracciamento per poter seguire la spedizione.',
  'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)':
    'Vuoi dare un’occhiata ai metodi di pagamento accettati o alla policy relativa a cambi e resi? Puoi trovare tutto qui. ("qui" linkato a pagina customer care)',
  'Get inspired We think you might love these': 'Lasciati ispirare Pensiamo che questi capi potrebbero piacerti',
  'Sign up for our newsletter': 'Iscriviti alla nostra newsletter',
  'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.':
    'Cliccando su “Iscriviti”, acconsento che le mie informazioni vengano utilizzate per profilazione marketing.',
  'Re-enter password': 'Reinserisci password',
  'Get inspired': 'Lasciati ispirare',
  'We think you might love these': 'Pensiamo che questi capi potrebbero piacerti',
  'Confirmation request has been sent.': 'La richiesta di conferma è stata inviata.',
  'The order has been paid with a gift card': "L'ordine è stato pagato con una gift card",
  'Enter gift card code': 'Inserisci il codice della gift card',
  'Paid with gift card': 'Pagato con gift card',
  'Gift card applied successfully.': 'Gift card applicata correttamente.',
  'Unable to apply gift card': 'Impossibile applicare la gift card',
  'An error occurred': 'Si è verificato un errore',
  'please retry. If the problem persist, you can': 'ti invitiamo a riprovare. Se il problema persiste, puoi',
  'create a new cart': 'creare un nuovo carrello',
  'Your cart is outdated and must be reloaded': 'Il tuo carrello non è più valido e deve essere ricaricato',
  Reload: 'Ricarica',
  'My billing and shipping address are the same': "Usa l'indirizzo di spedizione anche per la fatturazione",
  'You reached the maximum saleable quantity': 'Hai raggiunto la massima quantità acquistabile',
  'Your request was submitted successfully; we will get back to you as soon as possible.':
    'La tua richiesta è stata inviata con successo; sarai ricontattato appena possibile.',
  'Request sent': 'Richiesta inviata',
  'Reset filters': 'Azzera filtri',
  'This field cannot contain a semicolon (;)': 'Questo campo non può contenere un punto e virgola (;)',
  'Customer service': 'Assistenza clienti',
  'Where to find us': 'Dove trovarci',
  'Here are the benefits of being a Colfert customer and order directly from our site. REGISTER or LOGIN.':
    'Ecco i vantaggi di essere un cliente Colfert e ordinare direttamente dal nostro sito. REGISTRATI o ACCEDI.',
  'items available': 'articoli a disposizione',
  'Technical assistance specialized': 'Assistenza tecnica specializzata',
  'Delivery guaranteed 24/72 hours from order': 'Consegna garantita 24/72 h dall’ordine',
  'Being different, prepared, in demand, being able to get where others cannot compete. These are some of the objectives to be pursued to differentiate ourselves in the market and which we achieve thanks to the services we build around our customers, because COLFERT is also "the service factory".':
    'Essere diversi, preparati, richiesti, riuscire ad arrivare dove gli altri non possono competere. Sono questi alcuni degli obiettivi da perseguire per differenziarci nel mercato e che raggiungiamo grazie ai servizi che costruiamo attorno ai nostri clienti, perché COLFERT è anche “la fabbrica dei servizi”.',
  'Handles and accessories': 'Maniglie e accessori',
  Tool: 'Ferramenta',
  'Darkening systems': 'Sistemi oscuranti',
  'Adhesives, foams and sealants': 'Adesivi, schiume e sigillanti',
  Paints: 'Vernici',
  'Main menu': 'Menù principale',
  'Training events': 'Eventi formazione',
  'Certification exams': 'Esami di Cerficiazione',
  Regulations: 'Normative',
  'Fake text to simulate a title': 'Testo finto per simulare un titolo',
  '18 and 31 May 2023': '18 e 31 maggio 2023',
  'Our next appointments': 'I nostri prossimi appuntamenti',
  'Course dedicated to installers on the installation of closures blinds and sun screens':
    'Corso dedicato ai posatori sulla posa delle chiusure oscuranti e delle schermature solari',
  'other professional growth opportunities': 'altre opportunità di crescita professionale',
  IN_STOCK: 'Disponibile in magazzino',
  'Add to wishlist': 'Aggiungi a wishlist',
  Details: 'Dettagli',
  Next: 'Successivo',
  Previous: 'Precente',
  'Clear all filters': 'Azzera tutti i filtri',
  'Show all results': 'Mostra tutti i risultati',
  'The Colfert training': 'La formazione Colfert',
  'Request INFO': 'Richiedi INFO',
  'My favorites': 'I miei preferiti',
  'My Documents': 'I miei documenti',
  'My cart': 'II mio carrello',
  'Download area': 'Area download',
  'My data': 'I miei dati',
  'Read more': 'Leggi di più',
  'company name': 'rag. sociale',
  name: 'Nome',
  'type of company': 'Settore',
  surname: 'Cognome',
  address: 'Indirizzo',
  country: 'Paese',
  city: 'Città',
  state: 'Stato',
  phone: 'Telefono',
  'VAT number': 'Partita IVA',
  'write a request': 'Scrivi la richiesta',
  'send application': 'Invia domanda',
  email: 'e-mail',
  'discover other products': 'scopri altri prodotti',
  'Recently viewed': 'Visti di recente',
  'Read less': 'Leggi di meno',
  'News on display ': 'Novità in vetrina',
  'Best-selling items': 'Articoli più venduti',
  'Your application was sent': 'La tua candidatura è stata inviata.',
  'There was an error while sending your application, please contact us':
    'Si è verificato un errore durante l’invio della tua candidatura, ti preghiamo di contattarci.',
  'We can’t seem to find the page you are looking for': 'Non riusciamo a trovare la pagina che stai cercando',
  'Error Code : 404': 'Codice errore: 404',
  'Back To Home Page': 'Torna alla pagina iniziale',
  'Are you want to share ?': 'Vuoi condividere?',
  'Price per set': 'Price per set',
  'Add to favourites': 'Aggiungi ai preferiti',
  'Remove from Wishlis': 'Rimuovi dalla Wishlist',
  'User already exists': 'Email già registrata',
  'Having read the information': "Presa visione dell'informativa",
  'Yes, I give my consent for my personal data to be used to execute my request':
    'Si, esprimo il consenso affinchè i miei dati personali vengano utilizzati per dare esecuzione alla mia richiesta',
  'Consent for marketing purposes': 'Consenso per finalità di marketing',
  'I consent to my personal data being used for marketing and commercial activities (newsletters, promotions, etc.)':
    'Acconsento che i dati personali vengano utilizzati per attività di marketing e commerciali (newsletter, promozioni, ecc…)',
  'Click here for full information': "Clicca qui per l'informativa completa",
  'Download attached documentation': 'Scarica i documenti allegati',
  see: 'vedi',
  download: 'scarica',
  'Please make sure your passwords match': 'Perfavore controlla che le password siano uguali.',
  'User not allowed to login, missing login permission': 'Utente non abilitato al login.',
  rowId: 'Numero Riga',
  rowType: 'Tipo Riga',
  productCode: 'Codice articolo',
  productDescription: 'Descrizione articolo',
  umManaged: 'Unità di misura',
  quantityManaged: 'Quantità',
  quantityManagedRemaining: 'Quantità residua',
  unitPriceNet: 'Prezzo netto',
  totalRow: 'Totale riga',
  rowClosed: 'Riga chiusa',
  supplier: 'Codice fornitore',
  id: 'Progressivo',
  year: 'Anno',
  documentType: 'Tipo documento',
  documentNumber: 'Numero documento',
  documentDate: 'Data documento',
  customerCode: 'Codice cliente',
  customerReference: 'Riferimento cliente',
  agentCode: 'Codice agente',
  differentDestinationCode: 'Numero destinazione diversa',
  differentDestinationName: 'Nome destinazione diversa',
  differentDestinationAddress: 'Indirizzo destinazione diversa',
  differentDestinationZip: 'CAP destinazione diversa',
  differentDestinationCity: 'Località destinazione diversa',
  differentDestinationProvince: 'Provincia destinazione diversa',
  documentTransportationFee: 'Spese di trasporto',
  parcelsNumber: 'Numero colli',
  giftTotal: 'Totale omaggi',
  cig: 'CIG',
  cup: 'CUP',
  orderType: 'Tipo ordine',
  payment: 'Pagamento',
  note: 'Note',
  documentPath: 'Link PDF documento',
  'Welcome to Colfert Italia | Only for professionals with VAT number': 'Benvenuto in Colfert | Solo per professionisti con P.IVA',
  'Look for the products you need': 'Cerca i prodotti di cui hai bisogno',
  'Request a quote': 'Preventivo o informazioni',
  '(VAT excluded)': '(IVA esclusa)',
  'Bulky product, request a quote': 'Prodotto ingombrante, richiedi un preventivo',
  'Price for set': 'Prezzo per set',
  Close: 'Chiudi',
  'Are you sure?': 'Sei sicuro?',
  'Are you sure you want to remove this item from your cart?': 'Sei sicuro di voler rimuovere questo articolo dal carrello?',
  'Back to shopping': 'Torna allo shopping',
  'Coupon removed successfully.': 'Coupon rimosso con successo.',
  'Unable to remove coupon.': 'Non è stato possibile rimuovere il coupon.',
  'Coupon applied successfully.': 'Coupon applicato con successo.',
  'Unable to apply coupon.': 'Impossibile applicare il coupon.',
  'The request has been sent, an email to change your password will arrive shortly.':
    'La richiesta è stata inviata, arriverà a breve un email per il cambio password.',
  'Error while sending password recovery email.': 'Errore durante linvio dell’e-mail di recupero della password.',
  'Fields marked with * are required': 'I campi contrassegnati con * sono obbligatori',
  'Not available for selected configuration': 'Cambio configurazione',
  'View product detail': 'Vedi dettaglio del prodotto',
  Taxes: 'IVA',
  'Set as default billing address': 'Usa come indirizzo di fatturazione di default',
  'Save this address in my address book': 'Salva nei miei indirizzi',
  'USE THIS ADDRESS': 'USA QUESTO INDIRIZZO',
  'SELECT AN ADDRESS ABOVE': 'SELEZIONA UN INDIRIZZO QUI SOPRA',
  'USE THE SELECTED ADDRESS': 'USA L’INDIRIZZO SELEZIONATO',
  'USE CURRENT SHIPPING ADDRESS AS BILLING ADDRESS': 'USA L’INDIRIZZO DI SPEDIZIONE COME FATTURAZIONE',
  'USE AN EXISTING ADDRESS': 'USA UN INDIRIZZO ESISTENTE',
  'USE A NEW ADDRESS': 'USA UN NUOVO INDIRIZZO',
  'SELECT AN ADDRESS': 'SELEZIONA UN INDIRIZZO',
  'Make the order': 'Acquista',
  'Your order includes a cumbersome item. There will be an additional charge on the shipping fee.':
    'Il tuo ordine contiene un oggetto ingombrante. Verrà applicata una tariffa aggiuntiva per la spedizione.',
  'Your order includes an item that is not available in a warehouse at the moment.':
    'Il tuo ordine contiene almeno un prodotto non disponibile immediatamente a magazzino.',
  'The material can be shipped with shipping times and costs to be defined. You will be contacted by the sales office.':
    'Il materiale è spedibile con tempi e costi di spedizione da definire. Sarete contattati dall’ufficio vendite.',
  'HIDE ORDER SUMMARY': 'NASCONDI RIEPILOGO ORDINE',
  'SHOW ORDER SUMMARY': 'MOSTRA RIEPILOGO ORDINE',
  'HIDE ITEMS RECAP': 'NASCONDI RIEPILOGO DEI PRODOTTI',
  'SHOW ITEMS RECAP': 'MOSTRA RIEPILOGO DEI PRODOTTI',
  'HIDE ADDRESSES RECAP': 'NASCONDI RIEPILOGO INDIRIZZI',
  'SHOW ADDRESSES RECAP': 'MOSTRA RIEPILOGO INDIRIZZI',
  'There was some error while trying to fetch payment methods. We are sorry, please try later.':
    'C’è stato un errore durante il caricamento dei metodi di pagamento. Per favore riprova più tardi.',
  'There was some error while trying to select this payment method. We are sorry, please try with different payment method or later.':
    'C’è stato un problema durante la selezione del metodo di pagamento. Selezionane un altro o riprova più tardi.',
  'There are no payment methods available. We are sorry, please try later.':
    'Non ci sono metodi di pagamento disponibili. Per favore riprova più tardi.',
  'Payment currently unavailable': 'Metodo di pagamento al momento non disponibile.',
  'I have read and agree to the': 'Ho letto e accetto',
  'Expiration Date': 'Data di Scadenza',
  'Card Number': 'Numero di Carta',
  Street: 'Via',
  'Set as default shipping address': 'Usa come indirizzo di spedizione di default',
  'ADD A NEW ADDRESS': 'AGGIUNGI UN NUOVO INDIRIZZO',
  'SELECT A SHIPPING METHOD': 'SELEZIONA UN METODO DI SPEDIZIONE',
  'inserted qty error': 'La quantità inserita non è valida',
  'Error adding item to cart.': 'C’è stato un errore durante l’aggiunta del prodotto al carrello. Per favore riprova o contatta l’assistenza.',
  'Error removing item from cart.': 'C’è stato un errore durante la rimozione del prodotto dal carrello. Per favore riprova o contatta l’assistenza.',
  'Error updating item quantity.':
    'C’è stato un errore durante l’aggiornamento della quantità del prodotto nel carrello. Per favore riprova o contatta l’assistenza.',
  'Error loading cart.': 'C’è stato un errore durante il caricamento del carrello. Per favore riprova o contatta l’assistenza.',
  'Error clearing cart.': 'C’è stato un errore durante la pulizia del carrello. Per favore riprova o contatta l’assistenza.',
  'Error applying coupon.': 'C’è stato un errore durante l’aggiunta del coupon. Per favore riprova o contatta l’assistenza.',
  'Error removing coupon.': 'C’è stato un errore durante la rimozione del coupon. Per favore riprova o contatta l’assistenza.',
  'Operation successful.': 'Operazione avvenuta con successo.',
  'Before you can add to cart, all attributes must be selected.':
    'Prima di aggiungere il prodotto al carrello seleziona tutte le caratteristiche del prodotto.',
  'An error occurred while creating your account. Please retry or contact customer support.':
    'C’è stato un errore nella creazione del tuo account. Per favore riprova o contatta il nostro customer care.',
  'Account created successfully!': 'Account creato correttamente!',
  'Mail sent to agent': 'Email inviata correttamente',
  'AGENT REVIEW': 'Salva per dopo',
  'Order items': 'Articoli nell’ordine',
  'Pickup in store': 'Ritiro in negozio',
  'Field must contain only letters, numbers and spaces': 'Il campo deve contenere solo lettere, numeri e spazi',
};
